.User-container {
    display: inline-block;
    cursor: pointer;
}

.User-container,
.User-icon {
    height: 45px;
    width: 45px;
}

.User-icn-btn {
    border: 0;
    background: transparent;
    outline: none !important;
    padding: 0;
}

.User-menu {
    position: absolute;
    z-index: 3;
    background: #fff;
    transition: ease 1s all;
    padding: 10px 0;
    top: -8px;
    right: -15px;
    width: 100vw;
    height: 100vh;
}

.User-menu.active {
    animation: show-menu .5s ease;
}

.User-menu-item {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none !important;
    height: 45px;
}

.User-menu-item:hover {
    background: #e5e5e5;
}

.User-menu-item span {
    padding-left: 20px;
    float: left;
}

.User-menu-item svg {
    margin-left: 25px;
    float: left;
}

.User-closer {
    border: none;
    outline: none !important;
    background: transparent;
    padding: 15px;
}

@media screen and (min-width: 768px) {
    .User-menu {
        position: absolute;
        width: 250px;
        right: 0;
        top: 46px;
        height: auto;
        box-shadow: 0 4px 13px 0px rgba(0,0,0,.1);
    }
}



@keyframes show-menu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes hide-menu {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
/* @media screen and (min-width: 992px) {
    .User-container,
    .User-icon {
        width: 54px;
        height: 54px;
    }
} */
