.FiltersBox-main-container {
    width: 100%;
    padding: 1em;
    border-bottom: 1px solid #eaeaea;
}

.FiltersBox-active {
    display: inline-block;
}

.FiltersBox-hidden {
    display: none;
}
