.Searcher-container {
    display: inline-block;
    height: 40px;
    width: 100%;
    position: relative
}

.Searcher-input {
    height: 40px;
    width: 100%;
    border: 1px solid #C7CCD1;
    border-radius: 3px;
    outline: none !important;
    padding: 5px 40px 5px 5px;
}

.Searcher-input::placeholder {
    color: #9B9B9B;
}

.Searcher-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.Searcher-label {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    margin: 0;
}
