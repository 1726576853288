.Videos-container {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 60px);
    flex-grow: 100;
    padding: 25px 30px;
    overflow: auto;
}

.Video-info-label {
    color: #a9a9a9;
}

.Video-info-details {
    font-weight: 700;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 80% !important;
    }

    .modal-content {
        height: calc(100vh - 400px) !important;
    }

    /* .Videos-list-container {
        max-height: calc(100vh - 340px);
        height: 100%;
        overflow: auto;
    } */
}

.Videos-list-container {
    max-height: calc(100vh - 340px - 95px);
    height: 100%;
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.Videos-list-container-35 {
    max-height: calc(100vh - 340px - 95px - 35px);
    height: 100%;
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.modal-lists-box {
    overflow: auto;
    height: calc(100vh - 200px - 210px);
}

.gray-bg {
    background: #EFF1F2;
}
