.TableItemCounter-container {
    width: 100%;
    background: #F6F7F8;
    height: 70px;
    padding: 15px;
    margin-bottom: 10px;
}

.TableItemCounter-title-container {
    display: inline-block;
    line-height: 20px;
    padding-left: 15px;
}

.TableItemCounter-chip {
    background: #37adef;
    color: #fff;
    padding: 0px 10px;
    border-radius: 10px;
    margin-right: 2px;
}
