.TableEditor-container {
    width: 100%;
    background: #EFF1F2;
    height: 105px;
    padding: 15px;
    margin-bottom: 10px;
}

.TableEditor-details {
    font-size: 12px;
    color: #696969;
}
