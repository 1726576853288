span.has-error {
    display: block;
    color: red;
}

input.has-error {
    border: 1px solid red !important;
    box-shadow: 0 0 0 0.2rem rgba(255,0,0,.25) !important;
}

.drop-zone-section {
    padding: 60px 50px;
}

.drop-zone-div {
    border: 1px dashed #979797;
    outline: none !important;
    cursor: pointer;
}

.drop-zone-loading-container {

}

.DropPromo-loader-container {
    max-height: 24px;
}

.DropPromo-loader-background {
    background: lightgrey;
    border-radius: 12px;
    position: relative;
}

.DropPromo-loader-progress-bar {
    background: #b4b3ff;
    width: 50%;
    float: left;
    border-radius: 12px;
}

.DropPromo-loader-progress-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
}

.DropPromo-hourpicker .react-time-picker__wrapper {
    border: none !important;
    text-align: center;
}
