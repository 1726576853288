.Sidebar-container {
    display: inline-block;
    background: #F6F7F8;
    width: 100%;
    height: calc(100vh - 60px);
    transition: ease .5s all;
    z-index: 2;
}

.toggle-on {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
}

.toggle-off {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(0);
}

.closer-btn {
    margin-right: 20px;
    margin-top: 16px;
    outline: none !important;
    box-shadow: none !important;
}

.sidebar-items {
    padding: 0;
    list-style: none;
}

.sidebar-items li {
    padding:  4px 0;
}

.sidebar-items li a {
    text-decoration: none !important;
    color: #494950;
}

@media screen and (min-width: 768px) {
    .Sidebar-container {
        background: #F6F7F8;
        box-shadow: 0 5px 10px rgba(0,0,0,.2);
        width: 240px;
        transform: translateX(0);
    }

    .toggle-on {
        position: relative;
        width: 240px;
        height: calc(100vh - 60px);
    }

    .toggle-off {
        position: relative;
        width: 72px;
        height: calc(100vh - 60px);
    }

    .toggle-off .sidebar-menu-container div ul li a div .menu-title {
        animation: decrease-span .5s ease;
        display: none;
    }

    .toggle-on .sidebar-menu-container div ul li a div .menu-title {
        animation: increase-span .5s ease;
        display: inline-block;
    }

    .toggle-off .sidebar-menu-container .toggle-content span {
        animation: decrease-span .5s ease;
        display: none;
    }

    .toggle-on .sidebar-menu-container .toggle-content span {
        animation: increase-span .5s ease;
    }

    .toggle-on .sidebar-menu-container .toggle-content svg {
        display: none;
    }

    .toggle-off .sidebar-menu-container .toggle-content svg {
        display: inline-inline-block;
    }
}

@keyframes decrease-span {
    0% {
        display: inline-block;
    }
    100% {
        display: none;
    }
}

@keyframes increase-span {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: inline-block;
        opacity: 0;
    }
}
