.DevicesCustomer-container {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 60px);
    flex-grow: 100;
    padding: 25px 30px;
}

.DevicesCustomer-box {
    height: calc(100vh - 174px);
    overflow: auto;
    width: 100%;
}

.log-container {
    height: 100%;
    overflow: auto;
    height: calc(100vh - 300px);
}

.DevicesCustomer-tag-chip {
    background: #37adef;
    color: #fff;
    padding: 0px 10px;
    border-radius: 10px;
    margin-right: 2px;
    font-size: 12px;
}

.DevicesCustomer-row-active {
    height: 70px;
    background: #F7F8F9;
}

.DevicesCustomer-video-list {
    max-height: calc(100vh - 190px);
    height: 100%;
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.ListBox-container {
    height: calc(100% - 40px) !important;
}
