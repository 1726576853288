.ListBox-container {
    border: 1px solid #E8E8E8;
    padding: 20px;
    overflow: auto;
}

.ListBox-item {
    list-style: none;
    padding: 0;
}

.ListBox-li {
    border:none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 10px;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 5px;
    outline: none !important;
}

.ListBox-li.active {
    background: #E8E2FF;
}

.ListBox-li:hover {
    background: #d2c7fb;
}
