.Header-container {
    background: #EFF1F2;
    width: 100%;
    height: 60px;
}

.Header-logo {
    height: 45px;
}

/* @media screen and (min-width: 992px) {
    .Header-container {
        background: #EFF1F2;
        width: 100%;
        height: 85px;
    }

    .Header-logo {
        height: 54px;
    }
} */

.btn-pulse-yt {
    height: 40px;
    width: 40px;
    margin-right: 16px;
    border-radius: 50%;
    padding: 0;
    outline: none !important;
}

.btn-pulse-yt-active {
    animation: shadow-pulse .5s ease-out;
}

/* .btn-pulse-yt:active {
  animation: pulse 0.6s infinite ease-out;
} */

@keyframes shadow-pulse {
     0% {
         background:rgba(0, 0, 0, 0.2);
         /*box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);*/
     }
     100% {
         background:rgba(0, 0, 0, 0);
         /*box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);*/
     }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
