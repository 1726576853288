.Devices-container {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 60px);
    flex-grow: 100;
    padding: 25px 30px;
    overflow: auto;
}

.Devices-box {
    height: calc(100vh - 174px);
}

.log-container {
    height: 100%;
    overflow: auto;
    height: calc(100vh - 300px);
}

.Devices-tag-chip {
    background: #37adef;
    color: #fff;
    padding: 0px 10px;
    border-radius: 10px;
    margin-right: 2px;
    font-size: 12px;
}
