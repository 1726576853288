.sidebar-div {
	height: 40px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    color: #000;
}

.active .sidebar-div {
	background: #e6e6e6;
}

.sidebar-div:hover {
	background: #d1d1d1;
}