.DeviceEdit-container {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 60px);
    flex-grow: 100;
    padding: 25px 30px;
}

.DeviceEdit-box {
    height: calc(100vh - 174px);
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.DeviceEdit-header {
    height: 105px;
    background: #EFF1F2;
}

.DeviceEdit-hours-color {
    color: #7F60EA;
}

.DeviceEdit-row-default {
    height: 70px;
    background: #EAE6FF;
}

.DeviceEdit-row-active {
    height: 70px;
    background: #F7F8F9;
}

.DeviceEdit-input {
    height: 40px;
    width: 100%;
    border: 1px solid #C7CCD1;
    border-radius: 3px;
    outline: none !important;
    padding: 5px;
}

.DeviceEdit-input::placeholder {
    color: #9B9B9B;
}

.DeviceEdit-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.additionalFooter {
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);
    background: #fff;
    text-align: center;
    z-index: 9999;
}

.modal-footer {
    padding: 0 !important;
}

.modal-body {
    height: 70% !important;
}

.DeviceEdit-items-container {
    overflow: auto;
    max-height: 400px;
    height: 400px;
}

.modal-lists-box {
    overflow: auto;
    height: calc(100vh - 200px - 210px);
}

.modal-lists-box2 {
    overflow: auto;
    height: calc(100vh - 480px);
}

.modal-lists-box3 {
    overflow: auto;
    height: calc(100vh - 325px);
}
