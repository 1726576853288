.Promo-container {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 60px);
    flex-grow: 100;
    padding: 25px 30px;
    overflow: auto;
}

.Promo-promo-container {
    max-height: calc(100vh - 340px);
    height: 100%;
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.on-playing-row {
    background: #cacaca;
}

.on-playing-row > div > div > div >.playing-title {
    color: #fff;
    font-weight: 500;
}

.promo-container {
    height: 100%;
    overflow: auto;
}

.playpromo-container {
    max-height: calc(100vh - 340px);
    height: 100%;
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.video-player-setts {
    text-align: center;
    display: inline-block;
}

.modal-video-box {
    overflow: auto;
    height: calc(100vh - 200px - 210px - 95px - 70px);
}

@media (min-width: 768px) {
    .promo-container {
        height: 100%;
        overflow: auto;
        height: calc(100vh - 620px);
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 80% !important;
    }

    .modal-content {
        height: calc(100vh - 100px) !important
    }
}

@media (max-height: 576px) {
    .modal-dialog {
        max-width: 80% !important;
    }

    .modal-content {
        height: calc(100vh - 50px) !important;
    }

    .promo-container {
        max-height: 210px;
        height: 100%;
    }

}

@media screen and (max-width: 375px) {
    .promo-container {
        max-height: 300px;
    }
}


@media (min-width: 1366px) {
    .modal-content {
        height: calc(100vh - 300px);
    }

    .promo-container {
        max-height: 500px;
        height: 100%;
    }
}
