
.alert-box {
    padding: 15px;
    color: #fff;
    border-radius: 4px;
    margin-top: 10px;
}

.alert-success {
    background: #94d09b;
    border: 1px solid #53e241;
    box-shadow: 0 0 8px rgba(111, 204, 104, 0.4);
}

.alert-error {
    background: #e88888;
    border: 1px solid #af5f5f;
    box-shadow: 0 0 8px rgba(253, 0, 0, 0.4);
}

.alert-box > span {
    font-size: 14px;
}
