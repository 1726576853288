.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.max-width-30px {
    max-width: 30px;
}

.max-width-35px {
    max-width: 35px;
}

.max-width-44px {
    max-width: 44px;
}

.max-width-50px {
    max-width: 50px;
}

.max-width-55px {
    max-width: 55px;
}

.max-width-70px {
    max-width: 70px;
}

.max-width-75px {
    max-width: 75px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.line-height-18px {
    line-height: 18px;
}
.mouse-pointer {
    cursor: pointer;
}

.font-color-fff {
    color: #fff;
}

.font-color-green {
    color: green;
}

.padding-top-10 {
	padding-top: 10px !important;
}

.table-btn {
    border: none;
    outline: none !important;
    background: transparent;
    border-radius: 3px;
    width: 55px;
    padding: 5px;
}

.table-btn:hover {
    background: #e6e6e6;
}

.table-btn-span {
    display: block;
    color: #7F60EA;
    font-size: 10px;
}

.btn-standard:disabled,
.btn-standard[disabled] {
    color: #fff;
    background: #c5c5c5 !important;
    border: none;
}

.btn-standard {
    color: #fff;
    background: #707070;
    border: none;
}

.btn-standard,
.btn-blank {
    height: 40px;
    border-radius: 5px;
    outline: none !important;
}

.btn-blank {
    background: transparent;
    color: #7F60EA;
    border: 1px solid #7F60EA;
}

.btn-blank:hover {
    background: rgba(127, 96, 234, 0.3);
    color: #fff;
}

.border-bottom-d8d8d8 {
    border-bottom: 1px solid #D8D8D8;
}

.primary-bg {
    background: #02ADEF;
}

.primary-bg:hover {
    background: #278AB6;
}

.success-bg {
    background: #2BB823;
}

.success-bg:hover {
    background: #1f8e18;
}

.unselect-text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.div-background-svg {
    border-radius: 50%;
    display: inline-block;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.drop-zone-loading-icon {
    animation: spin infinite 2s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.swal-text {
    text-align: center !important;
}


/**
 * CUSTOM CHECKBOX
 */
 .custom-checkbox-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin: 0;
    height: 20px;
 }

 .custom-checkbox-container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
 }

 .checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 20px;
   width: 20px;
   background-color: transparent;
   border: 1px solid #C7CCD1;
   border-radius: 3px;
 }

 .custom-checkbox-container:hover input ~ .checkmark,
 .custom-checkbox-container input:checked ~ .checkmark{
   border: 1px solid #7F60EA;
 }

 .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }

 .custom-checkbox-container input:checked ~ .checkmark:after {
   display: block;
 }

 .custom-checkbox-container .checkmark:after {
   left: 6px;
   top: 2.5px;
   width: 5px;
   height: 10px;
   border: solid #7F60EA;
   border-width: 0 3px 3px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
 }

.styless-btn {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    padding: 0;
}


 /*RADIO BUTTON*/
    .custom-radio .custom-control-label::before {
        background-color: #fff;
        border: 2px solid #ddd6fe;
    }

    /* This is the checked state */
    .custom-radio .custom-control-input:checked~.custom-control-label::before,
    .custom-radio .custom-control-input:checked~.custom-control-label::after {
        background-color: #a490fb;
        background-image: none !important;
        border-radius: 50%;
        border: 2px solid #ddd6fe;
    }

    /* active state i.e. displayed while the mouse is being pressed down */
    .custom-radio .custom-control-input:active ~ .custom-control-label::before {
        color: #fff;
        background-color: #a490fb;
    }

    /* the shadow; displayed while the element is in focus */
    .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none !important;
    }
    /*//RADIO BUTTON*/
/**
 * //CUSTOM CHECKBOX
 */

/**
 * CUSTOM PAGINATION
 */
    .pagination {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }

    .pagination li {
        margin-left: 2px;
        margin-right: 2px;

    }

    .pagination li {
        text-decoration: none;
    }

    .pagination li a {
        text-decoration: none;
        color: #d4ceff;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid;
        border-radius: 5px;
        outline: none !important;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
        supported by Chrome, Opera and Firefox */
    }

    .pagination li.active a:hover,
    .pagination li.active a {
        color: #F6F7F6;
        cursor: default;

        background: rgba(127, 96, 233, 0.5);
        border-color: rgba(127, 96, 233, 0.5);
    }

    .pagination li a:hover {
        text-decoration: none;
        color: #7f60e9;
        cursor: pointer;
        background: #F6F7F6;
    }

    .pagination li.disabled a {
        text-decoration: none;
        color: #cccccc;
        cursor: not-allowed;
        background: #e6e6e6;
    }
/**
 * //CUSTOM PAGINATION
 */
