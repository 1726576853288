.login-form-container {
}

span.has-error {
    display: block;
    color: red;
}

input.has-error {
    border: 1px solid red !important;
    box-shadow: 0 0 0 0.2rem rgba(255,0,0,.25) !important;
}

.Login-main-container {
    background: url('../../../assets/images/background/background_img.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Login-logo {
    width: auto;
    height: 7rem;
}

.Login-input {
    height: 50px;
    color: #FFF;
    border: none;
    outline: none !important;
    background: #7F60EA !important;
    border-radius: 5px;
    padding-left: 50px;
}

.Login-input:focus {
    color: #FFF !important;
}

.Login-input::placeholder {
    color: #b5acac;
}

.Login-icon-input {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
}

.Login-submit-btn {
    background-image: -ms-linear-gradient(left, #A56EFA 0%, #4B6DFE 100%) !important;
    background-image: -moz-linear-gradient(left, #A56EFA 0%, #4B6DFE 100%) !important;
    background-image: -o-linear-gradient(left, #A56EFA 0%, #4B6DFE 100%) !important;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #A56EFA), color-stop(100, #4B6DFE)) !important;
    background-image: -webkit-linear-gradient(left, #A56EFA 0%, #4B6DFE 100%) !important;
    background-image: linear-gradient(to right, #A56EFA 0%, #4B6DFE 100%) !important;
    height: 45px;
    border-radius: 22.5px;
    color: #ffffff !important;
    font-weight: 500;
}

.Login-submit-btn:hover {
    color: #b5acac !important;
}
