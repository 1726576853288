.TableSchedule-container {
    width: 100%;
    background: #F6F7F8;
    height: 70px;
    padding: 15px;
    margin-bottom: 10px;
}

.TableSchedule-title-container {
    display: inline-block;
    line-height: 20px;
    padding-left: 15px;
}
