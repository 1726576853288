.modal-header {
    border-bottom: none !important;
    padding: 0.5rem;
}

.modal-header button {
    outline: none !important;
}

.modal-body {
    min-height: 320px;
    height: 420px;
    padding: 0.1rem 1rem 0.1rem 1rem;
    overflow: auto;
}

.modal-footer {
    border-top: none !important;
}

.modal-footer {
    justify-content: center;
}
